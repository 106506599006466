import tableGetters from "@/store/templates/table/getters";
import { IDS } from "@/store/templates/table/getter-types";
import { denormalize } from "normalizr";
import { submission as submissionSchema } from "@/store/schema";

export default {
  ...tableGetters,
  [IDS]: state =>
    state.ids.map(submissionId =>
      denormalize(state.entity[submissionId], submissionSchema, state)
    )
};
