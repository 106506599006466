import tableState from "@/store/templates/table/state";
import { createEntityPlaceholders } from "@/store/helpers";

export default {
  ...tableState({
    state: [],
    subjects: [],
    countries: [],
    languages: [],
    companies: []
  }),
  ...createEntityPlaceholders([
    "lead",
    "offer",
    "country",
    "form",
    "website",
    "language",
    "subject",
    "company",
    "buddy",
    "user",
    "submissionRefinement",
    "refinementRule"
  ])
};
